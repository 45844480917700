
// Font color
$white: #ffffff;
$white-opacity80: #ffffffcc;
$black: #121212;
$black-zero-opacity: #00000000;
$light-gray-border: #d4d5d9;
$silver: #ccc;
$light-black: #121212bf;
$light-grey: #f0f0f0;
$grey: #a9a9a9;
$dark-grey: #666;
$medium-light-grey: #aaa;
$blue: #007bff;
$dark-blue: #0055af;
$alice-blue: #f4f8ff;
$yellow: #fdda0d;
$purple: #9370db;
$red: #ff0000;
$light-blue: #add8e6;
$orange: #ffa500;
$green: #0dce0d;
$dark-green: #0c650c;
$black-with-opacity: #0000001f; // opacity = 0.12
$mid-gray: #a9abb2;
$white-smoke: #f9f9f9;
$pink: #ffc0cb;
$dark-charcoal: #333;
$dark-charcoal-opacity20: #33333333;
$light-sea-green: #139b94;
$transparency-with-opacity:#00000080;
$hover-color: #eeeeee;
$light-silver: #F8F9FB;
$charcoal-blue: #3F5374;
$warm-orange:#E86231;
$light-silver: #F8F9FB;
$golden-yellow: #F4C753;
$golden-yellow-light: #FFFEF0;
$light-blue: #5599D5;
$light-pink: #F8F2F2;
$periwinkle: #E4E9F1; //A light, slightly muted blue with a hint of lavender
$designer-dark-green: #117A65;
$designer-green: #21BA45;
$designer-sky-blue: #5DADE2;
$designer-violet: #3633BF;
$designer-orange: #CF871B;
$designer-teal: #00B5AD;
$designer-light-blue: #2185D0;
$designer-dark-blue: #3C79B7;

// Font size
$xxl: 32px;
$xl: 24px;
$l: 16px;
$color-box-height: 30px;
$color-box-width: 30px;

// Font family
// $default: aktiv-grotesk-extended, sans-serif;
$default: "Plus Jakarta Sans", "Noto Sans", sans-serif;
